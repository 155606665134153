const getStatusCodeMessage = code => {
  switch (code) {
    case 1100:
      return 'User email not avaialbe in the token claims'

    case 1101:
      return 'You are not allowed to use the application. Please contact Clixray support'

    case 1102:
      return 'Cust ID is not allowed to use with your email. Please contact Clixray support'

    case 1103:
      return 'Token validation error, Please retry login'

    default:
      return `Auth error with status code ${code}`
  }
}

export default class AuthApiService {
    axiosInst= null;

    constructor(axiosInst) {
      this.axiosInst = axiosInst
    }

    async authorize(token, custId) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
      if (custId) {
        headers['X-CLX-Cust-Id'] = custId
      }

      const response = await this.axiosInst.post('/', { action: 'authorize' }, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(getStatusCodeMessage(response.data.statusCode))
      }
      throw new Error('invalid response')
    }
}
