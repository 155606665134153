import * as MutationTypes from './mutation-types'

export default {
  [MutationTypes.ALL_COHORT_DATA_READY](state, payload) {
    state.allCohorts = payload.data
  },

  [MutationTypes.COHORT_ANALYSIS_STATS_DATA_READY](state, payload) {
    state.cohortAnalysisStats = { ...state.cohortAnalysisStats, [payload.cohortId]: payload.data }
  },

  [MutationTypes.COHORT_STATUS_PULL_START](state, id) {
    state.statusPullCohort = [...state.statusPullCohort, id]
  },

  [MutationTypes.COHORT_STATUS_PULL_END](state, id) {
    state.statusPullCohort = state.statusPullCohort.filter(_id => _id !== id)
  },

}
