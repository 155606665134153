// import * as MutationTypes from '@/store/global-mutation-types'

import * as GlobalMutationTypes from '@/store/global-mutation-types'
import * as Mutation from './mutation-types'
import * as Consts from './consts'

// eslint-disable-next-line import/prefer-default-export
export const getAllCohort = async (context, force) => {
  try {
    if (context.getters.hasAllCohortList && force !== true) {
      return
    }
    const result = await context.getters.apiService.fetchAllCohort()
    context.commit(Mutation.ALL_COHORT_DATA_READY, { data: result })
  } catch (error) {
    context.commit(Mutation.ALL_COHORT_DATA_FAILED, { data: [] })
    throw error
  }
}

export const getCohortById = async (context, id) => {
  try {
    const result = await context.getters.apiService.fetchCohortById(id)
    return result
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const getCohortForEdit = async (context, id) => {
  try {
    const result = await context.getters.apiService.fetchCohortById(id)
    if (result.segments && result.segments.length > 0) {
      const segments = await context.getters.apiService.getSegments(result.segments)
      result.segments = segments
    }

    const prodGroupdIds = result.base_products.concat(result.target_products)
    const allProductGroups = await context.getters.apiService.getProductsGroups(prodGroupdIds)
    const prodHash = allProductGroups.reduce((dict, p) => ({ ...dict, [p.id]: p }), {})
    result.target_products = result.target_products.map(pid => ({ ...prodHash[pid] }))// .filter(p => p != null && p !== undefined)
    result.base_products = result.base_products.map(pid => ({ ...prodHash[pid] }))// .filter(p => p != null && p !== undefined)
    return result
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const searchSegments = async (context, search) => {
  try {
    const result = await context.getters.apiService.searchSegments(search)
    return result
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const searchProductsGroups = async (context, search) => {
  try {
    const result = await context.getters.apiService.searchProductsGroups(search)
    return result
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const createCohort = async (context, data) => {
  try {
    const user = context.rootGetters['user/googleUserProfile']
    const segments = data.segments ? data.segments.map(i => i.id) : undefined
    const baseProducts = data.base_products.map(p => p.id)
    const targetProducts = data.target_products.map(p => p.id)
    const params = {
      ...data,
      segments,
      base_products: baseProducts,
      target_products: targetProducts,
    }

    if (data.event_kind === Consts.PET_STAGE_CHANGE_EVENT_KIND) {
      params.event_det.from_stage = data.event_det.from_stage.value
      params.event_det.to_stage = data.event_det.to_stage.value
    }

    await context.getters.apiService.createCohort(params, user.getEmail())
    context.commit(`app/${GlobalMutationTypes.SHOW_SUCCESS}`, 'New cohort created succesfully', { root: true })
    context.dispatch('getAllCohort', true)
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const updateCohort = async (context, { id, data }) => {
  try {
    const segments = data.segments ? data.segments.map(i => i.id) : undefined
    const baseProducts = data.base_products.map(p => p.id)
    const targetProducts = data.target_products.map(p => p.id)
    const params = {
      ...data,
      segments,
      base_products: baseProducts,
      target_products: targetProducts,
    }

    if (data.event_kind === Consts.PET_STAGE_CHANGE_EVENT_KIND) {
      params.event_det.from_stage = data.event_det.from_stage.value
      params.event_det.to_stage = data.event_det.to_stage.value
    }

    await context.getters.apiService.updateCohort(id, params)
    context.commit(`app/${GlobalMutationTypes.SHOW_SUCCESS}`, 'New cohort created succesfully', { root: true })
    context.dispatch('getAllCohort', true)
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const addToRefreshQueue = async (context, id) => {
  try {
    await context.getters.apiService.addToRefreshQueue(id)
    context.commit(`app/${GlobalMutationTypes.SHOW_SUCCESS}`, 'Added to refresh queue', { root: true })
    context.dispatch('getAllJourney', true)
    context.dispatch('startJourneyStatusPull', id)
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const startCohortStatusPull = async (context, id) => {
  if (context.getters.hasCohortOnPull(id)) {
    return
  }

  context.commit(Mutation.COHORT_STATUS_PULL_START, id)

  try {
    const result = await context.getters.apiService.getCohortById(id)

    if (result.exec_status === 'SUCCEEDS' || result.status === 'FAILED') {
      context.commit(Mutation.COHORT_STATUS_PULL_END, id)
      context.dispatch('getAllCohort', true)
      return
    }
  } catch (error) {
    context.commit(Mutation.COHORT_STATUS_PULL_END, id)
    throw error
  }

  setTimeout(() => {
    context.commit(Mutation.COHORT_STATUS_PULL_END, id)
    context.dispatch('startCohortStatusPull', id)
  }, 10000)
}

export const getCohortAnalysisStats = async (context, { force, cohortId }) => {
  try {
    if (context.getters.hasCohortAnalysisStats(cohortId) && force !== true) {
      return
    }
    const result = await context.getters.apiService.fetchCohortAnalysisStats(cohortId)
    context.commit(Mutation.COHORT_ANALYSIS_STATS_DATA_READY, { data: result, cohortId })
  } catch (error) {
    context.commit(Mutation.COHORT_ANALYSIS_STATS_DATA_FAILED, { data: [], cohortId })
    throw error
  }
}

export const activateCohort = async (context, id) => {
  try {
    await context.getters.apiService.activateCohort(id)
    context.commit(`app/${GlobalMutationTypes.SHOW_SUCCESS}`, 'Cohort activated sucessfully', { root: true })
    context.dispatch('getAllCohort', true)
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const deactivateCohort = async (context, id) => {
  try {
    await context.getters.apiService.deactivateCohort(id)
    context.commit(`app/${GlobalMutationTypes.SHOW_SUCCESS}`, 'Cohort deactivated sucessfully', { root: true })
    context.dispatch('getAllCohort', true)
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const softDeleteCohort = async (context, id) => {
  try {
    await context.getters.apiService.softDeleteCohort(id)
    context.commit(`app/${GlobalMutationTypes.SHOW_SUCCESS}`, 'Soft deleted sucessfully', { root: true })
    context.dispatch('getAllCohort', true)
  } catch (error) {
    context.commit(`app/${GlobalMutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}
