import * as MutationTypes from '@/store/global-mutation-types'
import * as Mutation from './mutation-types'

export const getAllJourney = async (context, force) => {
  try {
    if (context.getters.hasAllJourneyList && force !== true) {
      return
    }
    const result = await context.getters.apiService.fetchAllJourney()
    context.commit(Mutation.ALL_JOURNEY_DATA_READY, { data: result })
  } catch (error) {
    context.commit(Mutation.ALL_JOURNEY_DATA_ERROR, { data: [] })
    throw error
  }
}

export const getJourneyById = async (context, id) => {
  const result = await context.getters.apiService.fetchJouernyById(id)
  return result
}

export const getCachedJourneyById = async (context, id) => {
  const cache = context.getters.getCachedJourney(id)
  if (cache != null) {
    return cache
  }
  try {
    const result = await context.getters.apiService.fetchJouernyById(id)
    return result
  } catch (error) {
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const startJourneyStatusPull = async (context, id) => {
  if (context.getters.hasJourneyOnPull(id)) {
    return
  }

  context.commit(Mutation.JOURNEY_STATUS_PULL_START, id)

  try {
    const result = await context.getters.apiService.fetchJouernyById(id)

    if (result.exec_status === 'SUCCEEDS' || result.status === 'FAILED') {
      context.commit(Mutation.JOURNEY_STATUS_PULL_END, id)
      context.dispatch('getAllJourney', true)
      return
    }
  } catch (error) {
    context.commit(Mutation.JOURNEY_STATUS_PULL_END, id)
    throw error
  }

  setTimeout(() => {
    context.commit(Mutation.JOURNEY_STATUS_PULL_END, id)
    context.dispatch('startJourneyStatusPull', id)
  }, 10000)
}

export const createJourney = async (context, data) => {
  try {
    const user = context.rootGetters['user/googleUserProfile']
    const result = await context.getters.apiService.createJourney(data, user.getEmail())
    context.commit(`app/${MutationTypes.SHOW_SUCCESS}`, 'New journey created succesfully', { root: true })
    context.dispatch('getAllJourney', true)
    context.dispatch('startJourneyStatusPull', result.id)
  } catch (error) {
    context.commit(Mutation.JOURNEY_ADD_EDIT_FAILED, { data: [] })
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const updateJourney = async (context, { id, data }) => {
  try {
    await context.getters.apiService.updateJourney(id, data)
    context.commit(`app/${MutationTypes.SHOW_SUCCESS}`, 'The journey updated succesfully', { root: true })
    context.dispatch('getAllJourney', true)
    context.dispatch('startJourneyStatusPull', id)
  } catch (error) {
    context.commit(Mutation.JOURNEY_ADD_EDIT_FAILED, { data: [] })
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const addToRefreshQueue = async (context, id) => {
  try {
    await context.getters.apiService.addToRefreshQueue(id)
    context.commit(`app/${MutationTypes.SHOW_SUCCESS}`, 'Added to refresh queue', { root: true })
    context.dispatch('getAllJourney', true)
    context.dispatch('startJourneyStatusPull', id)
  } catch (error) {
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const activateJourney = async (context, id) => {
  try {
    await context.getters.apiService.activateJourney(id)
    context.commit(`app/${MutationTypes.SHOW_SUCCESS}`, 'Journey activated sucessfully', { root: true })
    context.dispatch('getAllJourney', true)
  } catch (error) {
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const deactivateJourney = async (context, id) => {
  try {
    await context.getters.apiService.deactivateJourney(id)
    context.commit(`app/${MutationTypes.SHOW_SUCCESS}`, 'Journey deactivated sucessfully', { root: true })
    context.dispatch('getAllJourney', true)
  } catch (error) {
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const softDeleteJourney = async (context, id) => {
  try {
    await context.getters.apiService.softDeleteJourney(id)
    context.commit(`app/${MutationTypes.SHOW_SUCCESS}`, 'Journey has been soft deleted', { root: true })
    context.dispatch('getAllJourney', true)
  } catch (error) {
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const getJourneyStatistic = async (context, { id, force }) => {
  if (force !== true) {
    const cacheData = context.getters.getCachedStatisticData(id)
    if (cacheData) {
      return cacheData
    }
  }

  try {
    const result = await context.getters.apiService.fetchJourneyStats(id)
    context.commit(Mutation.JOURNEY_STATISTIC_DATA_READY, { data: result, id })
    return result
  } catch (error) {
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const getJourneyPartnerData = async (context, { id, force }) => {
  if (force !== true) {
    const cacheData = context.getters.getCachedPartnerData(id)
    if (cacheData) {
      return cacheData
    }
  }
  try {
    const result = await context.getters.apiService.fetchJourneyPartnerData(id)
    context.commit(Mutation.JOURNEY_PARTNER_DATA_READY, { data: result, id })
    return result
  } catch (error) {
    context.commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
    throw error
  }
}

export const getJourneySummery = async (context, { id, force }) => {
  const tJourney = context.dispatch('getCachedJourneyById', id)
  const tPartnerData = context.dispatch('getJourneyPartnerData', { id, force })
  const tJourneyStats = context.dispatch('getJourneyStatistic', { id, force })

  const obj = await Promise.all([tJourney, tPartnerData, tJourneyStats])
    .then(values => ({
      journey: values[0],
      partnerData: values[1],
      statistic: values[2],
    }))

  return obj
}

// export const getAllJourney = context => new Promise(resolve => {

//   context.getters.apiService.

//   setTimeout(() => {
//     context.commit(Mutation.ALL_JOURNEY_DATA_READY, { data: [] })
//     resolve()
//   }, 1000)
// })
