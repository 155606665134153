export const ALL_JOURNEY_DATA_READY = 'ALL_JOURNEY_DATA_READY'
export const ALL_JOURNEY_DATA_ERROR = 'ALL_JOURNEY_DATA_ERROR'
export const JOURNEY_ADD_EDIT_SUCCESS = 'JOURNEY_ADD_EDIT_SUCCESS'
export const JOURNEY_ADD_EDIT_FAILED = 'JOURNEY_ADD_EDIT_FAILED'
export const JOURNEY_STATUS_PULL_START = 'JOURNEY_STATUS_PULL_START'
export const JOURNEY_STATUS_PULL_END = 'JOURNEY_STATUS_PULL_END'
export const JOURNEY_PARTNER_DATA_READY = 'JOURNEY_PARTNER_DATA_READY'
export const JOURNEY_STATISTIC_DATA_READY = 'JOURNEY_STATISTIC_DATA_READY'
export const JOURNEY_STATISTIC_DATA_FAIL = 'JOURNEY_STATISTIC_DATA_FAIL'
export const JOURNEY_PARTNER_DATA_FAIL = 'JOURNEY_PARTNER_DATA_FAIL'
