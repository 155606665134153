import axios from 'axios'
import JourneyApiService from '../services/journey-api'

export const allJourney = state => state.allJourney
export const hasAllJourneyList = state => state.allJourney && state.allJourney.length
export const hasJourneyOnPull = state => id => state.statusPullJourney.indexOf(id) !== -1
export const getCachedPartnerData = state => id => state.journeyPartnerData[id]
export const getCachedStatisticData = state => id => state.journeyStatisticData[id]
export const getCachedJourney = state => id => state.allJourney.find(item => item.id === id)
export const apiService = (state, getters, rootState, roorGetters) => {
  const baseURL = roorGetters['apiConfig/eventJourneyEndpointUrl']
  const axiosInst = axios.create({ baseURL })
  const interceptorsSetup = roorGetters['apiConfig/setupGlobalAuthInterceptors']
  interceptorsSetup(axiosInst)
  return new JourneyApiService(axiosInst)
}
