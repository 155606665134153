export default class JourneyApiService {
    axiosInst= null;

    constructor(axiosInst) {
      this.axiosInst = axiosInst
    }

    async authorize(token) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
      const response = await this.axiosInst.post('/', { action: 'authorize' }, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(response.data.error)
      }
      throw new Error('invalid response')
    }

    async fetchAllJourney() {
      const headers = {
        'Content-Type': 'application/json',
      }

      const response = await this.axiosInst.post('/', { action: 'list-all-journey' }, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          const apiResult = response.data.data.map(item => ({
            created_by: item.admin,
            date_created: item.created,
            id: item.id,
            status: item.status,
            exec_status: item.exec_status,
            payload: item,
            name: item.name,
            hasFunnelData: item.counts && item.counts.length,
          }))
          return apiResult
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async fetchJouernyById(id) {
      const headers = {
        'Content-Type': 'application/json',
      }
      const response = await this.axiosInst.post('/', { action: 'get-journey', id }, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async createJourney(data, admin) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = {
        action: 'create-journey',
        name: data.name,
        stages: data.stages,
        admin,
      }

      const response = await this.axiosInst.post('/', payload, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async updateJourney(id, data) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = {
        action: 'update-journey',
        id,
        ...data,
      }

      const response = await this.axiosInst.post('/', payload, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async addToRefreshQueue(id) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = { action: 'refresh-journey', id }

      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async activateJourney(id) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = { action: 'activate-journey', id }

      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async deactivateJourney(id) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = { action: 'deactivate-journey', id }
      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async softDeleteJourney(id) {
      const headers = {
        'Content-Type': 'application/json',
      }
      const payload = { action: 'soft-delete-journey', id }
      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async fetchJourneyPartnerData(id) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = { action: 'get-journey-partner-stats', id }
      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async fetchJourneyStats(id) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = { action: 'get-journey-transition-stats', id }
      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }
}
