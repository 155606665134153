import * as MutationTypes from '@/store/global-mutation-types'
import * as ActionTypes from '@/store/global-action-types'
import axios from 'axios'
import AuthApiService from '../../services/auth-api'

const resolveCustIdName = id => {
  switch (id) {
    case 'demo':
      return 'Demo'

    default:
      return id
  }
}

const storeGetters = {
  authTokenResolver: state => new Promise((resolve, reject) => {
    if (!state.authContext) {
      reject()
      return
    }

    if (!state.authContext.isSignedIn.get()) {
      reject()
      return
    }

    const user = state.authContext.currentUser.get()
    const response = user.getAuthResponse()
    const now = new Date().getTime()

    if (response.expires_at < (now - 60 * 10)) {
      response.reloadAuthResponse().then(res => resolve(res.id_token), () => reject())
    } else {
      resolve(response.id_token)
    }
  }),
  googleUserProfile: state => (state.authContext ? state.authContext.currentUser.get().getBasicProfile() : null),
  getCurrentCustId: _state => _state.currentCustId,
  getCustIdsWithName: state => state.allowedCustIds.map(custId => ({ id: custId, name: resolveCustIdName(custId) })),
}

export default {
  namespaced: true,
  state() {
    return {
      authContext: null,
      allowedCustIds: [],
      currentCustId: localStorage.getItem('ej:lastCustId'),
    }
  },
  getters: storeGetters,
  mutations: {
    [MutationTypes.SET_GOOGLE_LOGIN_USER](state, payload) {
      state.authContext = payload
    },
    [MutationTypes.SET_CURRENT_CUST_ID](state, custId) {
      state.currentCustId = custId
      localStorage.setItem('ej:lastCustId', custId)
    },
    setSessionInfo(state, payload) {
      state.currentCustId = payload.currentCustId
      localStorage.setItem('ej:lastCustId', payload.currentCustId)
      state.allowedCustIds = payload.allowedCustIds
    },
    clearSessionInfo(state) {
      state.currentCustId = null
      localStorage.removeItem('ej:lastCustId')
    },
  },
  actions: {
    async [ActionTypes.VALIDATE_USER_LOGIN]({ commit, getters, rootGetters }, { authContext, showError }) {
      commit(MutationTypes.SET_GOOGLE_LOGIN_USER, authContext)
      const baseURL = rootGetters['apiConfig/eventJourneyEndpointUrl']
      const axiosInst = axios.create({ baseURL })
      const api = new AuthApiService(axiosInst)
      const token = await getters.authTokenResolver
      const custId = getters.getCurrentCustId
      try {
        const result = await api.authorize(token, custId)
        commit('setSessionInfo', { allowedCustIds: result.allowed_cust_ids, currentCustId: result.cust_id })
        return result
      } catch (error) {
        commit('clearSessionInfo')
        if (showError) {
          commit(`app/${MutationTypes.SHOW_ERROR}`, error.message, { root: true })
        }
        throw error
      }
    },
  },
}
