import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

const state = {
  allCohorts: [],
  cohortAnalysisStats: {},
  statusPullCohort: [],
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
