export default class CohortApiService {
    axiosInst= null;

    constructor(axiosInst) {
      this.axiosInst = axiosInst
    }

    async fetchAllCohort() {
      const headers = {
        'Content-Type': 'application/json',
      }

      const response = await this.axiosInst.post('/', { action: 'list-all-cohort' }, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          const apiResult = response.data.data.map(item => ({
            created_by: item.admin,
            date_created: item.created,
            id: item.id,
            status: item.status,
            payload: item,
            name: item.name,
          }))
          return apiResult
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async fetchCohortById(id) {
      const headers = {
        'Content-Type': 'application/json',
      }
      const response = await this.axiosInst.post('/', { action: 'get-cohort', id }, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async createCohort(data, admin) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = {
        ...data,
        action: 'create-cohort',
        admin,
      }

      const response = await this.axiosInst.post('/', payload, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async searchSegments(search) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = {
        action: 'search-segments',
        search,
      }

      const response = await this.axiosInst.post('/', payload, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async getSegments(ids) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = {
        action: 'get-segments',
        ids,
      }

      const response = await this.axiosInst.post('/', payload, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async searchProductsGroups(search) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = {
        action: 'search-products-groups',
        search,
      }

      const response = await this.axiosInst.post('/', payload, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async getProductsGroups(ids) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = {
        action: 'get-products-groups',
        ids,
      }

      const response = await this.axiosInst.post('/', payload, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async activateCohort(id) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = { action: 'activate-cohort', id }

      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async deactivateCohort(id) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = { action: 'deactivate-cohort', id }

      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async softDeleteCohort(id) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = { action: 'soft-delete-cohort', id }

      const response = await this.axiosInst.post('/', payload, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async updateCohort(id, data) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const payload = {
        action: 'update-cohort',
        id,
        ...data,
      }

      const response = await this.axiosInst.post('/', payload, { headers })
      if (response.data) {
        if (response.data.statusCode === 0) {
          return response.data.data
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async fetchCohortAnalysisStats(cohortId) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const response = await this.axiosInst.post('/', {
        action: 'get-cohort-analysis-results',
        id: cohortId,
      }, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          const apiResult = response.data.data
          return apiResult
        }
        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }

    async addToRefreshQueue(cohortId) {
      const headers = {
        'Content-Type': 'application/json',
      }

      const response = await this.axiosInst.post('/', {
        action: 'refresh-cohort',
        id: cohortId,
      }, { headers })

      if (response.data) {
        if (response.data.statusCode === 0) {
          const apiResult = response.data.data
          return apiResult
        }

        throw new Error(`Server send error code ${response.data.statusCode}`)
      }
      throw new Error('Server return invalida data')
    }
}
