import * as MutationTypes from './mutation-types'

export default {
  [MutationTypes.ALL_JOURNEY_DATA_READY](state, payload) {
    state.allJourney = payload.data
  },

  [MutationTypes.ALL_JOURNEY_DATA_ERROR](state, payload) {
    state.error = payload
  },

  [MutationTypes.JOURNEY_STATUS_PULL_START](state, id) {
    state.statusPullJourney = [...state.statusPullJourney, id]
  },

  [MutationTypes.JOURNEY_PARTNER_DATA_READY](state, payload) {
    state.journeyPartnerData = { ...state.journeyPartnerData, ...{ [payload.id]: payload.data } }
  },

  [MutationTypes.JOURNEY_STATISTIC_DATA_READY](state, payload) {
    state.journeyStatisticData = { ...state.journeyStatisticData, ...{ [payload.id]: payload.data } }
  },

  [MutationTypes.JOURNEY_STATUS_PULL_END](state, id) {
    state.statusPullJourney = state.statusPullJourney.filter(_id => _id !== id)
  },
}
