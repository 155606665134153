const state = () => ({
  eventJourneyEndpoint: '/api',
})

const getters = {
  eventJourneyEndpointUrl: _state => `${_state.eventJourneyEndpoint.trim('/')}/`,
  setupGlobalAuthInterceptors: (_state, _getters, rootState, rootGetters) => axiosInst => axiosInst.interceptors.request.use(config => {
    const originalRequest = config
    const custId = rootGetters['user/getCurrentCustId']
    return rootGetters['user/authTokenResolver'].then(idToken => {
      originalRequest.headers.Authorization = `Bearer ${idToken}`
      if (custId) {
        originalRequest.headers['X-CLX-Cust-Id'] = `${custId}`
      }
      return originalRequest
    })
  }, error => Promise.reject(error)),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations: {
    LOGIN_ACTION(s, token) {
      // eslint-disable-next-line no-param-reassign
      s.currentToken = token
    },
  },
  actions: {
    tokenLogin({ commit }, token) {
      commit('LOGIN_ACTION', token)
      return Promise.resolve()
    },
  },
}
