import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/modules/journey/pages/DashboardPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/GoogleLoginPage.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/journey/add',
      name: 'journey-add',
      component: () => import('@/modules/journey/pages/JourneyAddEditPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add new Journey',
        breadcrumb: [
          {
            text: 'Add new Journey',
            active: true,
          },
        ],
      },
    },
    {
      path: '/journey/edit/:id',
      name: 'journey-edit',
      component: () => import('@/modules/journey/pages/JourneyEditPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit Journey',
        breadcrumb: [
          {
            text: 'Add new Journey',
            active: true,
          },
        ],
      },
    },
    {
      path: '/journey/funnel/:id',
      name: 'journey-funnel',
      component: () => import('@/modules/journey/pages/FunnelGraphPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Journey funnel',
        breadcrumb: [
          {
            text: 'Journey funnel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cohort/purchase-journey',
      name: 'purchase-journey',
      component: () => import('@/modules/cohort/pages/PurchaseJourneyPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Purchase journey',
        breadcrumb: [
          {
            text: 'Purchase journey',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cohort/add',
      name: 'cohort-add',
      component: () => import('@/modules/cohort/pages/CohortAddPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add new Cohort',
        breadcrumb: [
          {
            text: 'Add new Cohort',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cohort/edit/:cohort_id',
      name: 'cohort-edit',
      component: () => import('@/modules/cohort/pages/CohortEditPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edit cohort analysis',
        breadcrumb: [
          {
            text: 'Edit cohort analysis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cohort/analysis/stats/:cohort_id',
      name: 'cohort-analysis-stats',
      component: () => import('@/modules/cohort/pages/CohortAnalysisStatsPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Cohort analysis stats',
        breadcrumb: [
          {
            text: 'Cohort analysis stats',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Event Journey',
        breadcrumb: [
          {
            text: 'Event Journey',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.getters['user/authTokenResolver'].then(() => next(), () => next('/login'))
  }
  next()
})

export default router
