import axios from 'axios'
import CohortApiService from '../services/cohort-api'

export const allCohort = state => state.allCohorts

export const hasAllCohortList = state => state.allCohorts && state.allCohorts.length
export const hasCohortAnalysis = state => cohortId => state.cohortAnalysis && state.cohortAnalysis[cohortId] !== undefined
export const hasCohortAnalysisStats = state => cohortId => state.cohortAnalysisStats && state.cohortAnalysisStats[cohortId] !== undefined
export const listAnalysisForCohort = state => cohortId => (state.cohortAnalysis && state.cohortAnalysis[cohortId] !== undefined ? state.cohortAnalysis[cohortId] : [])
export const listAnalysisStatsForCohort = state => cohortId => (state.cohortAnalysisStats && state.cohortAnalysisStats[cohortId] !== undefined ? state.cohortAnalysisStats[cohortId] : [])
export const hasCohortOnPull = state => id => state.statusPullCohort.indexOf(id) !== -1

export const apiService = (state, getters, rootState, roorGetters) => {
  const baseURL = roorGetters['apiConfig/eventJourneyEndpointUrl']
  const axiosInst = axios.create({ baseURL })
  const interceptorsSetup = roorGetters['apiConfig/setupGlobalAuthInterceptors']
  interceptorsSetup(axiosInst)
  return new CohortApiService(axiosInst)
}
